import React from "react";
import Carousel from "react-multi-carousel";
import { getItemCountFromResponsive } from "@Utils";
import { CarouselCustomDot } from "@Modules/common/CarouselCustomDot";
import { ITemplate } from "@Templates/ITemplate";

import commonStyles from "../../root/linktemp.module.scss";
import styles from "./testimonialsSection.module.scss";
import { TestimonialCard } from "./TestimonialCard";

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 0 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
    },
};

export const TestimonialsSection = ({
    hideComponent,
    title,
    testimonialsData,
    onTestimonialClick,
    pauseTestimonialsAutoplay,
}: ITemplate.ITestimonialsSectionProps): JSX.Element | null => {
    if (hideComponent) return null;

    return (
        <div className={styles.root}>
            <div className={commonStyles.sectionTitle}>{title}</div>
            <div className={styles.carouselContainer}>
                <Carousel
                    responsive={responsive}
                    arrows={false}
                    showDots
                    autoPlay={
                        testimonialsData.length >
                            getItemCountFromResponsive(responsive) &&
                        !pauseTestimonialsAutoplay
                    }
                    infinite
                    renderDotsOutside
                    customDot={<CarouselCustomDot design="dot" applyTheme />}
                    className={styles.carousel}
                >
                    {testimonialsData.map(testimonial => (
                        <TestimonialCard
                            data={testimonial}
                            onTestimonialClick={e =>
                                onTestimonialClick(e, testimonial)
                            }
                            key={testimonial.id}
                        />
                    ))}
                </Carousel>
            </div>
        </div>
    );
};
