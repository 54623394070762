/* eslint-disable jsx-a11y/anchor-has-content */
import { ITemplate } from "@Templates/ITemplate";
import { SECTION_IDS } from "@Utils";
import { classNames } from "repoV2/utils/common/render/classNames";
import React from "react";
import { FollowerCountIcons } from "@Modules/hostPage/FollowerCountIcons";
import ExlyImage from "repoV2/features/Common/modules/ExlyImage/ExlyImage";
import styles from "./aboutSection.module.scss";
import commonStyles from "../../root/linktemp.module.scss";

export const AboutSection = ({
    preview,
    shortDescription,
    profileImg,
    name,
    hideSocialIcons,
    socialLinks,
}: ITemplate.IAboutSectionProps) => {
    return (
        <div className={commonStyles.sectionContainer}>
            <a id={SECTION_IDS.about} className={commonStyles.sectionAnchor} />
            {profileImg ? (
                <ExlyImage
                    alt={name || ""}
                    src={profileImg}
                    fetchWidth={300}
                    width="175"
                    height="175"
                    className={styles.profileImage}
                />
            ) : null}
            {preview && !profileImg ? (
                <div
                    className={classNames(styles.profileImage, styles.preview)}
                >
                    Your Profile Picture
                </div>
            ) : null}

            {name ? (
                <h1 className={commonStyles.sectionTitle}>{name}</h1>
            ) : null}

            {shortDescription}

            <FollowerCountIcons
                socialLinks={socialLinks}
                hideSocialIcons={hideSocialIcons}
            />
        </div>
    );
};
