import React from "react";
import { classNames } from "repoV2/utils/common/render/classNames";
import {
    getMaxLengthBasedOnDeviceWidth,
    ImagePreviewIconSvg,
    QuoteBeginIconSvg,
    TESTIMONIAL_DATA_TYPE,
    trimText,
} from "@Utils";
import { IHost } from "@Interfaces";
import { VideoPlayer } from "@Modules/common/";
import { ButtonV2 } from "@Modules/common/ButtonV2";
import { ITemplate } from "@Templates/ITemplate";
import { useScreenSize } from "repoV2/utils/common/render/screen";
import ExlyImage from "repoV2/features/Common/modules/ExlyImage/ExlyImage";
import styles from "./testimonialCard.module.scss";

const TESTIMONIAL_ENUM: ITemplate.ITestimonialCardContentProps = {
    [TESTIMONIAL_DATA_TYPE.VIDEO]: ({ onTestimonialClick, data }) => (
        <div
            className={classNames(styles.mediaContainer, styles.videoContainer)}
        >
            <div
                className={classNames(styles.mediaOverlay, styles.videoOverlay)}
                onClick={onTestimonialClick}
            />
            <VideoPlayer
                videoUrl={data.media}
                light
                optimizeUsingThumbnail
                width="100%"
                height="100%"
                className={styles.video}
            />
        </div>
    ),
    [TESTIMONIAL_DATA_TYPE.IMAGE]: ({ onTestimonialClick, data }) => (
        <div
            className={classNames(styles.mediaContainer, styles.imageContainer)}
        >
            <div
                className={classNames(styles.mediaOverlay, styles.imageOverlay)}
                onClick={onTestimonialClick}
            >
                <ImagePreviewIconSvg height="40px" />
            </div>
            <ExlyImage
                fetchWidth={500}
                alt={data.content}
                src={data.media}
                className={styles.image}
            />
        </div>
    ),
    [TESTIMONIAL_DATA_TYPE.TEXT]: ({
        onTestimonialClick,
        data,
        showReadMore,
        maxLength,
    }) => (
        <div className={styles.textContainer}>
            <div className={styles.quoteContainer}>
                <QuoteBeginIconSvg className={styles.quote} />
            </div>
            <div className={styles.text} key={data.content}>
                {trimText(data.content, maxLength!)}
                {showReadMore ? (
                    <>
                        <ButtonV2
                            design="plain"
                            applyTheme
                            onClick={onTestimonialClick}
                            className={styles.readMore}
                        >
                            Read More
                        </ButtonV2>
                    </>
                ) : null}
            </div>
        </div>
    ),
};

const AuthorSection = ({
    data,
}: {
    data: IHost.ITestimonialItem;
}): JSX.Element => {
    return (
        <div className={styles.authorContainer}>
            <ExlyImage
                fetchWidth={92}
                className={styles.authorImage}
                alt=""
                loading="lazy"
                src={data?.cover_image}
            />
            <div className={styles.authorName}>{trimText(data.name, 30)}</div>
        </div>
    );
};

export const TestimonialCard = ({
    data,
    onTestimonialClick,
}: ITemplate.ITestimonialCardProps): JSX.Element => {
    const screenSize = useScreenSize();

    const maxLength = getMaxLengthBasedOnDeviceWidth(
        [
            [769, 520],
            [250, 350],
        ],
        screenSize.width
    );

    const showReadMore: boolean = !!(data?.content?.length > maxLength);

    const componentProps = {
        onTestimonialClick,
        data,
        ...(data.type === TESTIMONIAL_DATA_TYPE.TEXT && {
            showReadMore,
            maxLength,
        }),
    };

    const Component = TESTIMONIAL_ENUM[data?.type];

    return (
        <div className={styles.container}>
            <Component {...componentProps} />
            <AuthorSection data={data} />
        </div>
    );
};
