import React from "react";
import { getCopyrightString } from "repoV2/utils/common/dataTypes/string";
import { PoweredByExly } from "repoV2/features/CreatorPages/modules/PoweredByExly";
import styles from "./footer.module.scss";

export const Footer = () => (
    <div className={styles.root}>
        <hr className={styles.divider} />
        <div className={styles.exlyBrandingContainer}>
            <PoweredByExly
                exlyTextClass={styles.exlyLogo}
                exlyClickDisabled={false}
            />
            <br />
            <div>{getCopyrightString()}</div>
        </div>
    </div>
);
