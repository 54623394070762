import React from "react";
import { ITemplate } from "@Templates/ITemplate";
import { Navbar } from "@Modules/common";
import { MEDIA } from "@Utils";
import { CUSTOM_CODES_POSITION } from "repoV2/constants/customCodes/customCodes.constants";
import { getOptimisedImageUrl } from "repoV2/features/Common/modules/ExlyImage/utils/getOptimisedImageUrl";
import { AboutSection } from "../components/AboutSection";
import { EventSection } from "../components/EventSection";
import { BottomAboutSection } from "../components/BottomAboutSection";
import { TestimonialsSection } from "../components/TestimonialsSection";
import { Footer } from "../components/Footer";
import styles from "./linktemp.module.scss";
import { CustomCodeSection } from "../components/CustomCodeSection/CustomCodeSection";

const LinkTemp = ({
    navbarProps,
    aboutSectionProps,
    eventSectionsProps,
    testimonialsSectionProps,
}: ITemplate.IProps): JSX.Element => {
    const backgroundImage: string = getOptimisedImageUrl({
        imageUrl:
            aboutSectionProps.coverImg || MEDIA.DEFAULT.BACKGROUND.LINKTEMP,
        fetchWidth: 2160,
    });

    /**
     * Why css is kept here?
     * The background property did not work in partially keeping it here and partially in css file.
     */
    const backgroundFilter = `linear-gradient(90deg,rgba(255, 255, 255, 0.7) 0%,rgba(255, 255, 255, 0.5) 25%,rgba(255, 255, 255, 0) 100%)`;
    const backgroundStyle = {
        backgroundImage: `${backgroundFilter}, url(${backgroundImage})`,
    };

    return (
        <div className={styles.root}>
            <Navbar {...navbarProps} />
            <div className={styles.background} style={backgroundStyle}>
                <div className={styles.container}>
                    <AboutSection {...aboutSectionProps} />
                    <CustomCodeSection
                        position={CUSTOM_CODES_POSITION.MAIN_CONTENT_START}
                    />
                    <EventSection {...eventSectionsProps} />
                    <BottomAboutSection {...aboutSectionProps} />
                    <TestimonialsSection {...testimonialsSectionProps} />
                    <CustomCodeSection
                        position={CUSTOM_CODES_POSITION.MAIN_CONTENT_END}
                    />
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default LinkTemp;
