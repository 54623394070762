import { ButtonV2 } from "@Modules/common/ButtonV2";
import React from "react";
import { ITemplate } from "@Templates/ITemplate";
import VideoPlayer from "repoV2/components/common/VideoPlayer/VideoPlayer";
import styles from "./bottomAboutSection.module.scss";
import commonStyles from "../../root/linktemp.module.scss";

export const BottomAboutSection = ({
    preview,
    introVideo,
    hideGetInTouch,
    getInTouchCtaText,
    onGetInTouchClick,
}: ITemplate.IAboutSectionProps) => {
    return (
        <div className={commonStyles.sectionContainer}>
            {introVideo || preview ? (
                <>
                    <div className={commonStyles.sectionTitle}>Intro Video</div>
                    {introVideo ? (
                        <VideoPlayer
                            className={styles.videoContainer}
                            width="100%"
                            videoUrl={introVideo}
                        />
                    ) : (
                        "< Your Intro Video will be displayed here >"
                    )}
                </>
            ) : null}
            {hideGetInTouch ? null : (
                <>
                    <ButtonV2
                        applyTheme
                        design="rounded corners"
                        className={styles.getInTouchButton}
                        onClick={() => {
                            onGetInTouchClick();
                        }}
                    >
                        {getInTouchCtaText}
                    </ButtonV2>
                </>
            )}
        </div>
    );
};
