import { ButtonV2 } from "@Modules/common/ButtonV2";
import { ITemplate } from "@Templates/ITemplate";
import { MEDIA } from "@Utils";
import React from "react";
import ExlyImage from "repoV2/features/Common/modules/ExlyImage/ExlyImage";
import styles from "./eventCard.module.scss";

export const EventCard = ({
    data,
    onEventListingClick,
}: {
    data: ITemplate.IEventListingData;
    onEventListingClick: ITemplate.IEventSectionsProps["onEventListingClick"];
}) => {
    return (
        <div onClick={() => onEventListingClick(data)} className={styles.root}>
            <div className={styles.container}>
                <div className={styles.left}>
                    <ExlyImage
                        src={data.cover_image || MEDIA.DEFAULT.CARD_IMAGE}
                        fetchWidth={200}
                        className={styles.photo}
                    />
                </div>
                <div className={styles.right}>
                    <div className={styles.title}>{data.title}</div>
                    <div
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: data.short_description,
                        }}
                        className={styles.shortDescription}
                    />
                </div>
            </div>
            <ButtonV2
                applyTheme
                design="rounded corners"
                className={styles.ctaButton}
            >
                {data.ctaText}
            </ButtonV2>
        </div>
    );
};
