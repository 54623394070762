import React from "react";
import { CustomCodeSection as CustomCodeSectionBase } from "@Modules/customCode/CustomCodeSection";
import styles from "../../root/linktemp.module.scss";

export const CustomCodeSection = ({ position }: { position: number }) => (
    <CustomCodeSectionBase
        position={position}
        Title={({ children }) => (
            <div className={styles.sectionTitle}>{children}</div>
        )}
        Section={({ children }) => (
            <section className="pb-5">{children}</section>
        )}
    />
);
